<template>
  <allComponent />
</template>

<script>
import allComponent from './components/allComponent.vue';

export default {
  name: 'App',
  components: {
    allComponent
  }
}

</script>
